<template>
   <article class="featured-product">
      <div class="featured-product__inner">
         <img :src="product.image" :alt="product.imageDescription" class="featured-product__img" />
      </div>

      <div class="featured-product__wrap">
         <h3 class="featured-product__title">
            <router-link
               :to="{ name: 'Product', params: { id: product.id } }"
               @click="closeModal"
               class="featured-product__link"
            >
               {{ product.name }}
            </router-link>
         </h3>

         <p class="featured-product__subtitle">Артикул: {{ product.vendorCode }}</p>

         <product-params :product="product" class="featured-product__product-params" />

         <div class="purchase">
            <p class="purchase__price">
               Цена: <span>{{ product.price }}&nbsp;{{ currency }}</span>
            </p>

            <button @click="addProductToCart(product)" class="purchase__btn btn">
               <cart-icon />
            </button>
         </div>
      </div>

      <button
         @click="removeProductFromFavorites(product)"
         class="featured-product__btn btn"
      ></button>
   </article>
</template>

<script>
   import ProductParams from './ProductParams';
   import CartIcon from '@/components/Icons/CartIcon';
   import { mapActions } from 'vuex';

   export default {
      name: 'ProductCard',

      components: {
         ProductParams,
         CartIcon
      },

      props: {
         product: { type: Object, required: true }
      },

      inject: ['openModal', 'closeModal'],

      methods: {
         ...mapActions('cart', { saveStateOfSelectedProduct: 'saveProductState' }),
         ...mapActions('favorites', ['saveProductState']),

         removeProductFromFavorites(product) {
            this.saveProductState({ product, isRemove: true });
         },

         addProductToCart(product) {
            this.saveStateOfSelectedProduct({ product, isRemove: false });

            setTimeout(() => {
               this.openModal({ name: 'ModalCart', isActive: true });
            }, 200);
         }
      }
   };
</script>
