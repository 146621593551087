<template>
   <svg
      id="Cart_1"
      role="img"
      aria-labelledby="uniqueTitleID"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 97.623 97.623"
      style="width: 20px; height: 20px; fill: #ffffff"
      xml:space="preserve"
   >
      <title id="uniqueTitleID">Добавить товар в корзину</title>
      <g>
         <path
            d="M78.617,65.562H44.543c-4.919,0-10.185-3.585-11.987-8.163l-13.77-34.953c-0.727-1.846-3.289-3.59-5.273-3.59H3.609C1.616,18.857,0,17.241,0,15.248c0-1.993,1.616-3.609,3.609-3.609h9.905c4.92,0,10.185,3.585,11.987,8.163l13.77,34.953c0.727,1.846,3.289,3.59,5.272,3.59h34.074c1.935,0,4.375-1.725,5.02-3.548l6.638-18.758c0.186-0.525,0.139-0.845,0.088-0.918c-0.052-0.073-0.338-0.223-0.895-0.223H42.474c-1.993,0-3.609-1.616-3.609-3.609c0-1.993,1.616-3.609,3.609-3.609h46.994c2.843,0,5.317,1.194,6.789,3.275c1.471,2.081,1.771,4.811,0.822,7.491l-6.638,18.758C88.783,61.89,83.589,65.562,78.617,65.562z"
         />
      </g>
      <g><circle cx="35.267" cy="77.964" r="8.02" /></g>
      <g><circle cx="86.689" cy="77.964" r="8.02" /></g>
      <g>
         <path
            d="M78.489,43.552H46.612c-0.997,0-1.804-0.808-1.804-1.804c0-0.997,0.808-1.804,1.804-1.804h31.877c0.997,0,1.804,0.808,1.804,1.804C80.293,42.744,79.486,43.552,78.489,43.552z"
         />
      </g>
      <g>
         <path
            d="M76.051,52.574H49.864c-0.996,0-1.804-0.808-1.804-1.804c0-0.997,0.808-1.804,1.804-1.804h26.188c0.997,0,1.804,0.808,1.804,1.804C77.855,51.766,77.048,52.574,76.051,52.574z"
         />
      </g>
   </svg>
</template>

<script>
   export default {
      name: 'CartIcon'
   };
</script>
